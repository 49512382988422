<template>
    <ClientOnly>
        <AuthState>
            <div class="custom-layout">
                <div class="container">
                    <slot />
                </div>
            </div>

            <div class="footer">
                <a href="/admin">Torna all'admin</a>
            </div>
        </AuthState>
    </ClientOnly>
</template>
<script setup lang="ts">

const { has_admin } = usePermissions();

if (!has_admin) {
    navigateTo("/");
}
</script>

<style scoped>
.footer{
    text-align: center;
    margin-top: 80px;
}
</style>
